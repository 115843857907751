var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-auto"},[(_vm.data.length == 0)?_c('p',{staticClass:"w-full text-center py-3"},[_vm._v(" "+_vm._s(_vm.noItemsMessage)+" ")]):_c('table',{staticClass:"w-full"},[_c('thead',[_c('tr',{staticClass:"text-left h-16"},[(_vm.selectable)?_c('th',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allSelected),expression:"allSelected"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.allSelected)?_vm._i(_vm.allSelected,null)>-1:(_vm.allSelected)},on:{"click":_vm.selectAll,"change":function($event){var $$a=_vm.allSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allSelected=$$c}}}})]):_vm._e(),_vm._l((_vm.dataTable[0]),function(value,name){return _c('th',{key:name,staticClass:"capitalize",class:{
            'cursor-pointer':
              _vm.sortable && _vm.options.sortableKeys.some(function (el) { return el === name; })
          },on:{"click":function($event){return _vm.orderBy(name)}}},[_c('div',{staticClass:"flex gap-1 items-center"},[_vm._t("th-column-value",[_vm._v(_vm._s(name))],{"label":name}),_c('i',{staticClass:"mdi text-lg",class:_vm.sortable && _vm.currentSort === name
                  ? _vm.currentSortDir == 'asc'
                    ? 'mdi-menu-up opacity-1'
                    : 'mdi-menu-down opacity-1'
                  : 'mdi-menu-down opacity-0'})],2)])}),_c('th',{staticClass:"text-right pr-5"},[_vm._v("Actions")])],2)]),_c('tbody',_vm._l((_vm.dataTable),function(row){return _c('tr',{key:row.id,attrs:{"data-id":row.id},on:{"click":function($event){return _vm.$emit('rowSelected', row.id)}}},[(_vm.selectable)?_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.selected),expression:"row.selected"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(row.selected)?_vm._i(row.selected,null)>-1:(row.selected)},on:{"change":function($event){var $$a=row.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(row, "selected", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(row, "selected", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(row, "selected", $$c)}}}})]):_vm._e(),_vm._l((row),function(column,field){return _c('td',{key:("" + (row.id + '_' + field)),staticClass:"text--ellipsis cursor-pointer"},[_vm._t("column-value",[_vm._v(_vm._s(column))],{"row":row,"column":column,"field":field})],2)}),_c('td',{staticClass:"text-right pr-5"},[_vm._t("actions",null,{"row":row})],2)],2)}),0)]),(_vm.showPagination)?_c('tool-bar',{scopedSlots:_vm._u([{key:"actionButtons",fn:function(){return [_c('div',{staticClass:"text-primary-400 hover:text-primary-500 cursor-pointer",on:{"click":function($event){return _vm.$emit('nextClickHandler')}}},[_vm._v(" Load Other Items ... ")])]},proxy:true}],null,false,915560260)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }