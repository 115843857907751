<template>
  <div class="overflow-auto">
    <p class="w-full text-center py-3" v-if="data.length == 0">
      {{ noItemsMessage }}
    </p>
    <table v-else class="w-full">
      <thead>
        <tr class="text-left h-16">
          <th v-if="selectable">
            <input type="checkbox" v-model="allSelected" @click="selectAll" />
          </th>
          <th
            v-for="(value, name) in dataTable[0]"
            :key="name"
            @click="orderBy(name)"
            class="capitalize"
            :class="{
              'cursor-pointer':
                sortable && options.sortableKeys.some(el => el === name)
            }"
          >
            <div class="flex gap-1 items-center">
              <slot name="th-column-value" :label="name">{{ name }}</slot>

              <i
                class="mdi text-lg"
                :class="
                  sortable && currentSort === name
                    ? currentSortDir == 'asc'
                      ? 'mdi-menu-up opacity-1'
                      : 'mdi-menu-down opacity-1'
                    : 'mdi-menu-down opacity-0'
                "
              ></i>
            </div>
          </th>
          <th class="text-right pr-5">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in dataTable"
          :key="row.id"
          class=""
          @click="$emit('rowSelected', row.id)"
          :data-id="row.id"
        >
          <td v-if="selectable">
            <input type="checkbox" v-model="row.selected" />
          </td>
          <td
            class="text--ellipsis cursor-pointer"
            v-for="(column, field) in row"
            :key="`${row.id + '_' + field}`"
          >
            <slot
              name="column-value"
              :row="row"
              :column="column"
              :field="field"
              >{{ column }}</slot
            >
          </td>
          <td class="text-right pr-5">
            <slot name="actions" :row="row"></slot>
          </td>
        </tr>
      </tbody>
    </table>
    <tool-bar v-if="showPagination">
      <template v-slot:actionButtons>
        <div
          class="text-primary-400 hover:text-primary-500 cursor-pointer"
          @click="$emit('nextClickHandler')"
        >
          Load Other Items ...
        </div>
      </template>
    </tool-bar>
  </div>
</template>

<script>
export default {
  components: {
    ToolBar: () => import('@/_components/ToolBar.vue')
  },
  name: 'TableData',
  props: {
    selectable: {
      type: Boolean,
      default: false
    },
    defaultSortField: String,
    defaultSortDir: {
      type: String,
      default: 'desc'
    },
    options: {
      type: Object,
      default: function() {
        return {}
      }
    },
    data: {
      type: Array,
      required: true
    },
    noItemsMessage: {
      type: String,
      default: 'Nessun elemento disponibile'
    },
    showPagination: {
      type: Boolean,
      default: false
    },

    sortable: {
      type: Boolean,
      default: false
    },
    isActiveNextBtn: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    allSelected: false,
    rowData: [],
    currentSort: '',
    currentSortDir: ''
  }),
  computed: {
    dataTable() {
      return this.rowData
    }
    // enableEdit() {
    //   return this.dataTable.some(dt => dt.selected)
    // }
  },
  watch: {
    // enableEdit: function(newValue) {
    //   console.log('Watching! ', newValue)
    // }
    rowData: {
      immediate: true,
      handler(n) {
        this.$emit('input', n)
      }
    },
    currentSortDir: {
      handler(n) {
        this.$emit('updateSort', n)
      }
    }
  },
  methods: {
    orderBy: function(field) {
      // console.log('%cTesto', 'color: lime', field)
      //if s == current sort, reverse
      if (
        this.options.sortableKeys != undefined &&
        this.options.sortableKeys.some(el => el === field) &&
        this.sortable
      ) {
        if (field === this.currentSort) {
          this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
        }
        this.currentSort = field
        this.updateDataSort()
      }
    },
    updateDataSort: function() {
      return this.rowData.sort((a, b) => {
        let mod = 1
        if (this.currentSortDir === 'desc') mod = -1
        if (a[this.currentSort] < b[this.currentSort]) return -1 * mod
        if (a[this.currentSort] > b[this.currentSort]) return 1 * mod
        return 0
      })
    }

    // selectAll() {
    //   this.allSelected = !this.allSelected
    //   this.dataTable.forEach(_dt => {
    //     _dt.selected = this.allSelected
    //   })
    // }
  },
  mounted() {
    let data = []

    this.currentSort = this.defaultSortField
    this.currentSortDir = this.defaultSortDir

    if (this.options.keys) {
      this.options.keys.forEach(key => {
        this.data.forEach((item, idx) => {
          let obj = { [[key]]: item[key] }
          data[idx] = { ...data[idx], ...obj }
        })
      })
    } else {
      data = this.data.map(row => {
        let rowKeys = Object.keys(row)

        var objRow = {}

        for (let n in rowKeys) {
          let objKey = { [[rowKeys[n]]]: row[rowKeys[n]] }
          objRow = { ...objRow, ...objKey }
        }

        // objRow.selected = false
        return objRow
      })
    }

    console.log('TABELLA FORMATTATA', data)

    this.rowData = data
    if (this.sortable) {
      this.updateDataSort()
    }
  }
}
</script>

<style lang="postcss">
th,
td {
  @apply p-2 text-sm;
}

th {
  @apply text-gray-600;
}

tr {
  @apply border-b border-gray-200;
}

tbody tr:hover {
  @apply bg-gray-100;
}

td {
  @apply text-gray-800 whitespace-normal;
  max-width: 130px;
}

.text--ellipsis {
  @apply truncate whitespace-nowrap;
}
</style>
